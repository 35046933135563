import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import { button, buttonText } from './Button.module.css';

const propTypes = {
  labelText: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

const Button = (props) => {
  const { labelText, onClick = noop } = props;

  return (
    <button onClick={onClick} type="button" className={button}>
      <h3 className={buttonText}>{labelText}</h3>
    </button>
  );
};

Button.propTypes = propTypes;

export default Button;
