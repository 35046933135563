import PropTypes from 'prop-types';
import * as React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby-plugin-react-i18next';

import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Button from '../components/Button';

import notFound from '../images/not-found.jpg';

const NotFoundPage = ({ data }) => {
  const { wpWebdata } = data;
  const attributes = wpWebdata;

  return (
    <Layout pageTitle="404" copyright={attributes.shibuya.copyright}>

      <Hero backgroundImage={notFound} attributes={attributes} />
      <article className="article">
        <h1>{attributes.shibuya.notFound}</h1>
        <p>{attributes.shibuya.notFoundLine}</p>
        <Link to="/">
          <Button labelText={attributes.shibuya.goBackHome} />
        </Link>
      </article>
    </Layout>
  );
};

export default NotFoundPage;

NotFoundPage.propTypes = {
  data: PropTypes.shape({
    wpWebdata: PropTypes.shape({
      title: PropTypes.string,
      shibuya: PropTypes.shape({
        notFound: PropTypes.string,
        goBackHome: PropTypes.string,
        notFoundLine: PropTypes.string,
        titleHome: PropTypes.string,
        titleAboutUs: PropTypes.string,
        titleMenu: PropTypes.string,
        titleReserve: PropTypes.string,
        titleFaq: PropTypes.string,
        titleContact: PropTypes.string,
        copyright: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export const query = graphql`
query ($language: String!) {
  wpWebdata(title: {eq: $language}) {
     title
     shibuya {
       notFound
       goBackHome
       notFound
       titleHome
       titleAboutUs
       titleMenu
       titleReserve
       titleFaq
       titleContact
       copyright
     }
  }
}
`;
